import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AsyncSearchMultiSelect, } from "../components/async_search_multi_select";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { accountsFetcher, contactsFetcher, usersFetcher, } from "./calls_table_fetchers";
export const getValFromSearchParams = (searchParams, val) => {
    // This function gets the values selected in the async filters from the URL
    const v = searchParams.get(val);
    return ((v === null || v === void 0 ? void 0 : v.split("$").map((valWithLabel) => {
        const [valLabel, valValue] = valWithLabel.split("~");
        return { label: valLabel, value: valValue };
    })) || []);
};
export const CallsAsyncFilters = (props) => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [resetHappened, setResetHappened] = React.useState(false);
    const [accounts, setAccounts] = React.useState(getValFromSearchParams(searchParams, "accounts"));
    const [contacts, setContacts] = React.useState(getValFromSearchParams(searchParams, "contacts"));
    const [users, setUsers] = React.useState(getValFromSearchParams(searchParams, "users"));
    useEffect(() => {
        if (props.asyncResetFlag) {
            setAccounts([]);
            setContacts([]);
            setUsers([]);
            props.setAsyncResetFlag(false);
            setResetHappened(true);
        }
    }, [props.asyncResetFlag]);
    useEffect(() => {
        const params = {
            accounts: accounts.length > 0
                ? accounts
                    .map((account) => `${account.label}~${account.value}`)
                    .join("$")
                : null,
            contacts: contacts.length > 0
                ? contacts
                    .map((contact) => `${contact.label}~${contact.value}`)
                    .join("$")
                : null,
            users: users.length > 0
                ? users.map((user) => `${user.label}~${user.value}`).join("$")
                : null,
            // When reset happens we don't want to override the reset happening in date_picker here
            from: resetHappened ? null : searchParams.get("from"),
            to: resetHappened ? null : searchParams.get("to"),
        };
        navigate(`?${createQueryString(params)}`);
    }, [accounts, contacts, users]);
    // Create query string
    const createQueryString = React.useCallback((params) => {
        const newSearchParams = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
        for (const [key, value] of Object.entries(params)) {
            if (value === null) {
                newSearchParams.delete(key);
            }
            else {
                newSearchParams.set(key, String(value));
            }
        }
        return newSearchParams.toString();
    }, [searchParams]);
    return (_jsxs(_Fragment, { children: [_jsx(AsyncSearchMultiSelect, { id: "account", dataFetcher: accountsFetcher, onChange: (options) => {
                    setAccounts(options);
                    if (resetHappened) {
                        setResetHappened(false);
                    }
                }, selectedOptions: accounts, placeholder: "Select account", wrapPills: true }), _jsx(AsyncSearchMultiSelect, { id: "contact", dataFetcher: contactsFetcher, onChange: (options) => {
                    setContacts(options);
                    if (resetHappened) {
                        setResetHappened(false);
                    }
                }, selectedOptions: contacts, placeholder: "Select contact", wrapPills: true }), _jsx(AsyncSearchMultiSelect, { id: "user", dataFetcher: usersFetcher, onChange: (options) => {
                    setUsers(options);
                    if (resetHappened) {
                        // When you change the filter after a reset
                        // We want to mark that the reset has been resolved
                        // Otherwise it will clear the date filter
                        setResetHappened(false);
                    }
                }, selectedOptions: users, placeholder: "Select user", wrapPills: true })] }));
};
