var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from "react";
import { getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable, } from "@tanstack/react-table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getSharedTableLayout } from "../common/endpoints";
import { useQuery } from "react-query";
export function useDataTable(_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var { pageCount = -1, filterFields = [], enableAdvancedFilter = false, enableAdvancedSorting = false } = _a, props = __rest(_a, ["pageCount", "filterFields", "enableAdvancedFilter", "enableAdvancedSorting"]);
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [columnOrderLocal, setColumnOrderLocal] = React.useState((_b = props.columnOrder) !== null && _b !== void 0 ? _b : []);
    const [sharedLayoutIdState, setSharedLayoutIdState] = React.useState(searchParams.get("layout"));
    const { data: sharedLayout } = useQuery(["getSharedTableLayoutLink", sharedLayoutIdState], () => getSharedTableLayout(sharedLayoutIdState), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    // Search params
    const search = {
        page: Number(searchParams.get("page")) || 1,
        per_page: Number(searchParams.get("per_page")) || 20,
    };
    const page = search.page;
    const perPage = (_f = (_c = search.per_page) !== null && _c !== void 0 ? _c : (_e = (_d = props.initialState) === null || _d === void 0 ? void 0 : _d.pagination) === null || _e === void 0 ? void 0 : _e.pageSize) !== null && _f !== void 0 ? _f : 20;
    // Create query string
    const createQueryString = React.useCallback((params) => {
        const newSearchParams = new URLSearchParams(searchParams === null || searchParams === void 0 ? void 0 : searchParams.toString());
        for (const [key, value] of Object.entries(params)) {
            if (value === null) {
                newSearchParams.delete(key);
            }
            else {
                newSearchParams.set(key, String(value));
            }
        }
        return newSearchParams.toString();
    }, [searchParams]);
    // Table states
    const [rowSelection, setRowSelection] = React.useState({});
    const [columnVisibility, setColumnVisibility] = React.useState((_h = (_g = props.initialState) === null || _g === void 0 ? void 0 : _g.columnVisibility) !== null && _h !== void 0 ? _h : {});
    // Handle server-side pagination
    const [{ pageIndex, pageSize }, setPagination] = React.useState({
        pageIndex: page - 1,
        pageSize: perPage,
    });
    const pagination = React.useMemo(() => ({
        pageIndex,
        pageSize,
    }), [pageIndex, pageSize]);
    React.useEffect(() => {
        if (!sharedLayoutIdState) {
            navigate(`?${createQueryString({
                page: pageIndex + 1,
                per_page: pageSize,
            })}`);
        }
    }, [pageIndex, pageSize, sharedLayoutIdState]);
    React.useEffect(() => {
        if (sharedLayoutIdState && sharedLayout && sharedLayout.length > 0) {
            navigate(`?${sharedLayout[0].query_string}`);
            if (table) {
                // Toggle column visibility and set sizing
                table.getAllColumns().forEach((column) => {
                    if (!column.getIsVisible() &&
                        Object.keys(sharedLayout[0].columns).includes(column.id)) {
                        column.toggleVisibility(true);
                    }
                    else if (column.getIsVisible() &&
                        !Object.keys(sharedLayout[0].columns).includes(column.id)) {
                        column.toggleVisibility(false);
                    }
                });
                table.setColumnSizing(sharedLayout[0].columns);
            }
            setColumnOrderLocal(sharedLayout[0].column_order);
        }
    }, [sharedLayout, sharedLayoutIdState]);
    const table = useReactTable(Object.assign(Object.assign({}, props), { pageCount, state: {
            pagination,
            columnVisibility,
            rowSelection,
            columnOrder: sharedLayoutIdState ? columnOrderLocal : props.columnOrder,
        }, enableRowSelection: true, onRowSelectionChange: setRowSelection, onPaginationChange: setPagination, onColumnVisibilityChange: setColumnVisibility, getCoreRowModel: getCoreRowModel(), getFilteredRowModel: getFilteredRowModel(), getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(), getFacetedRowModel: getFacetedRowModel(), getFacetedUniqueValues: getFacetedUniqueValues(), manualPagination: true, manualSorting: true, manualFiltering: true, columnResizeMode: "onChange" }));
    return { table };
}
