var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from "react";
import { flexRender, } from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow, } from "./table";
import { DataTablePagination } from "./data_table_pagination";
import classNames from "../class_names";
import { getCommonPinningStyles } from "../table_helpers";
import { ColumnResizer } from "./column_resizer";
import DragIndicatorIcon from "../icons/drag_indicator_icon";
// needed for table body level scope DnD setup
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors, } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { arrayMove, SortableContext, horizontalListSortingStrategy, useSortable, } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button } from "./button";
export function DataTable(_a) {
    var { table, floatingBar = null, footer = null, children, className, columnOrder, setColumnOrder } = _a, props = __rest(_a, ["table", "floatingBar", "footer", "children", "className", "columnOrder", "setColumnOrder"]);
    /**
     * Instead of calling `column.getSize()` on every render for every header
     * and especially every data cell (very expensive),
     * we will calculate all column sizes at once at the root table level in a useMemo
     * and pass the column sizes down as CSS variables to the <table> element.
     */
    const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}));
    function handleDragEnd(event) {
        const { active, over } = event;
        if (active && over && active.id !== over.id) {
            setColumnOrder((columnOrder) => {
                const oldIndex = columnOrder.indexOf(active.id);
                const newIndex = columnOrder.indexOf(over.id);
                return arrayMove(columnOrder, oldIndex, newIndex); //this is just a splice util
            });
        }
    }
    const columnSizeVars = React.useMemo(() => {
        const headers = table.getFlatHeaders();
        const colSizes = {};
        for (let i = 0; i < headers.length; i++) {
            const header = headers[i];
            colSizes[`--header-${i}-size`] = header.getSize();
            colSizes[`--col-${i}-size`] = header.column.getSize();
        }
        return colSizes;
    }, [
        // In some cases, the columns in the table are added after the BE API
        // resolves, and hence we want to recompute the sizing of the new columns
        table.getFlatHeaders(),
        table.getState().columnSizingInfo,
        table.getState().columnSizing,
    ]);
    const DraggableTableHeader = ({ header, index, }) => {
        const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
            id: header.column.id,
        });
        const style = Object.assign(Object.assign({}, getCommonPinningStyles({ column: header.column })), { opacity: isDragging ? 0.8 : 1, position: "relative", transform: CSS.Translate.toString(transform), transition: "width transform 0.2s ease-in-out", whiteSpace: "nowrap", width: `calc(var(--header-${index}-size) * 1px)`, zIndex: isDragging ? 1 : 0 });
        const styleForPinnedColumn = Object.assign(Object.assign({}, getCommonPinningStyles({ column: header.column })), { width: header.column.getSize() });
        return (_jsx(TableHead, Object.assign({ colSpan: header.colSpan, ref: setNodeRef, style: header.column.getIsPinned() ? styleForPinnedColumn : style }, { children: _jsxs("div", Object.assign({ className: "flex flex-row justify-start items-center" }, { children: [!!!header.column.getIsPinned() && (_jsx(Button, Object.assign({ variant: "ghost", size: "sm" }, attributes, listeners, { className: "text-wds-gray-6 cursor-move", disabled: !!header.column.getIsPinned() }, { children: !header.column.getIsPinned() && _jsx(DragIndicatorIcon, {}) }))), _jsx(ColumnResizer, { header: header }), header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())] })) }), header.id));
    };
    return (_jsxs("div", Object.assign({ className: classNames("space-y-2.5 overflow-auto", className) }, props, { children: [children, _jsx(DndContext, Object.assign({ collisionDetection: closestCenter, modifiers: [restrictToHorizontalAxis], onDragEnd: handleDragEnd, sensors: sensors }, { children: _jsx("div", Object.assign({ className: "overflow-auto rounded-md border h-[73vh]" }, { children: _jsxs(Table, Object.assign({ style: Object.assign(Object.assign({}, columnSizeVars), { width: table.getTotalSize() }) }, { children: [_jsx(TableHeader, Object.assign({ className: "sticky top-0 z-10" }, { children: table.getHeaderGroups().map((headerGroup) => (_jsx(TableRow, { children: _jsx(SortableContext, Object.assign({ items: columnOrder, strategy: horizontalListSortingStrategy }, { children: headerGroup.headers.map((header, index) => (_jsx(DraggableTableHeader, { header: header, index: index }))) })) }, headerGroup.id))) })), table.getState().columnSizingInfo.isResizingColumn ? (_jsx(MemoisedTBody, { table: table, columnOrder: columnOrder })) : (_jsx(TBody, { table: table, columnOrder: columnOrder })), footer && _jsx(TableFooter, { children: footer })] })) })) })), _jsxs("div", Object.assign({ className: "flex flex-col gap-2.5" }, { children: [_jsx(DataTablePagination, { table: table }), table.getFilteredSelectedRowModel().rows.length > 0 && floatingBar] }))] })));
}
function TBody({ table, columnOrder, }) {
    var _a;
    const DragAlongCell = ({ cell, index, }) => {
        const { isDragging, setNodeRef, transform } = useSortable({
            id: cell.column.id,
        });
        const style = Object.assign(Object.assign({}, getCommonPinningStyles({ column: cell.column })), { opacity: isDragging ? 0.8 : 1, position: "relative", transform: CSS.Translate.toString(transform), transition: "width transform 0.2s ease-in-out", width: `calc(var(--col-${index}-size) * 1px)`, zIndex: isDragging ? 1 : 0 });
        const styleForPinnedColumn = Object.assign(Object.assign({}, getCommonPinningStyles({ column: cell.column })), { width: cell.column.getSize() });
        return (_jsx(TableCell, Object.assign({ style: cell.column.getIsPinned() ? styleForPinnedColumn : style, ref: setNodeRef }, { children: flexRender(cell.column.columnDef.cell, cell.getContext()) }), cell.id));
    };
    return (_jsx(TableBody, { children: ((_a = table.getRowModel().rows) === null || _a === void 0 ? void 0 : _a.length) ? (table.getRowModel().rows.map((row) => (_jsx(TableRow, Object.assign({ "data-state": row.getIsSelected() && "selected" }, { children: row.getVisibleCells().map((cell, index) => (_jsx(SortableContext, Object.assign({ items: columnOrder, strategy: horizontalListSortingStrategy }, { children: _jsx(DragAlongCell, { cell: cell, index: index }) }), cell.id))) }), row.id)))) : (_jsx(TableRow, { children: _jsx(TableCell, Object.assign({ colSpan: table.getAllColumns().length, className: "h-24 text-center" }, { children: "No results." })) })) }));
}
// Memoize the TBody component
// This is essential when trying to resize 1000+ rows
// For small data it is fine though
const MemoisedTBody = React.memo(TBody, (prev, next) => {
    return (prev.table.options.data === next.table.options.data &&
        prev.table.getState().columnVisibility ===
            next.table.getState().columnVisibility &&
        prev.table.getState().columnSizing === next.table.getState().columnSizing);
});
