import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { ScrollArea } from "../components/scroll_area";
export const NoCrmObjectMappedPopoverPanel = ({ title, editButtonClickHandler, }) => {
    return (_jsxs("div", Object.assign({ className: "flex justify-between items-center self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-base font-medium text-wds-gray-4" }, { children: title })), _jsx("button", Object.assign({ onClick: editButtonClickHandler, className: "flex w-8 h-8 justify-center items-center" }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) }))] })));
};
const CallCrmDetailsPopover = (props) => {
    return (_jsxs(Popover, { children: [_jsx(PopoverTrigger, Object.assign({ disabled: props.disabled }, { children: props.button })), _jsx(PopoverContent, { children: _jsx(ScrollArea, Object.assign({ className: "[&>[data-radix-scroll-area-viewport]]:max-h-[300px] overflow-y-auto" }, { children: props.panel })) })] }));
};
export const PopoverOptions = {
    Default: "default",
    V3: "v3",
};
export const classesForPopover = (mappedToCrm, variant = PopoverOptions.Default) => {
    if (mappedToCrm) {
        return variant === PopoverOptions.Default
            ? "border-wds-gray-3 bg-wds-gray-1 text-wds-gray-6"
            : "border-wds-blue-2 bg-wds-blue-1 text-wds-blue-4";
    }
    else {
        return variant === PopoverOptions.Default
            ? "border-yellow-300 bg-yellow-100 text-yellow-800"
            : "border-dashed border-wds-gray-4 bg-none text-wds-gray-5";
    }
};
export const classesForPopoverIcon = (mappedToCrm, variant = PopoverOptions.Default) => {
    if (mappedToCrm) {
        return variant === PopoverOptions.Default
            ? "text-wds-gray-6"
            : "text-wds-blue-4";
    }
    else {
        return "text-yellow-600";
    }
};
export const classesForPopoverSvg = (mappedToCrm, variant = PopoverOptions.Default) => {
    if (mappedToCrm) {
        return variant === PopoverOptions.Default
            ? "fill-wds-gray-6"
            : "fill-wds-blue-4";
    }
    else {
        return "fill-yellow-600";
    }
};
export default CallCrmDetailsPopover;
