var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createColumnHelper } from "@tanstack/react-table";
import { useDataTable } from "../../table_hooks/use_data_table";
import { DataTable } from "../../components/data_table";
import { DataTableToolbarV2 } from "../../components/data_table_toolbar_v2";
import { WiserBackendQueryOperator, } from "../../common/query_builder/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { WiserBackendQueryConverter } from "../../common/query_builder/json_query_converter";
import { convertBackendFilterToSearchParams, convertSearchParamsToBackendFilter, } from "../../common/query_builder/search_params_utils";
import { WiserBackendSortRuleConverter } from "../../common/sort_rule_builder/backend_order_by_clause_converter";
import { convertBackendOrderByClausesToSearchParams, convertSearchParamsToBackendOrderBy, } from "../../common/sort_rule_builder/search_params_utils";
import { useQueryClient } from "react-query";
import { getPaginatedCrmData } from "../../common/endpoints";
import { getCrmAccountName } from "../../common/call_account_utils";
const FIELDS = [
    {
        id: "name",
        name: "Name",
        type: "string",
    },
    {
        id: "age",
        name: "Age",
        type: "number",
    },
    {
        id: "dob",
        name: "Date of Birth",
        type: "date",
    },
    {
        id: "email",
        name: "Email",
        type: "string",
    },
    {
        id: "profession",
        name: "Profession",
        type: "string",
    },
    {
        id: "is_active",
        name: "Is active",
        type: "boolean",
    },
    {
        id: "company",
        name: "Company",
        type: "string",
        meta: {
            model: "Company",
            relatedModelFieldName: "employee_id",
        },
    },
    {
        id: "12345",
        name: "Close Date",
        type: "date",
        meta: {
            model: "CrmOpportunityFieldValue",
            relatedModelFieldName: "opportunity_id",
            proxyFieldKey: "crm_field_id",
            proxyFieldValue: "datetime_value",
        },
    },
    {
        id: "account_id",
        name: "Account",
        type: "reference",
        meta: {
            model: "LiveCall",
        },
    },
    {
        id: "opportunity_id",
        name: "Opportunity",
        type: "reference",
        meta: {
            model: "LiveCall",
        },
    },
];
export const TestDataTable = () => {
    const data = [
        {
            name: "Alice Johnson",
            age: 30,
            dob: new Date(1993, 4, 15),
            email: "alice.johnson@example.com",
            profession: "Software Engineer",
            is_active: true,
            company: "Tech Corp",
            crm_data: [
                { field: { id: 12345, name: "Close Date" }, value: "2024-05-10" },
            ],
        },
    ];
    const columnHelper = createColumnHelper();
    const columns = useMemo(() => {
        return [
            {
                id: "name",
                accessorKey: "name",
                header: "Name",
                cell: (info) => info.getValue(),
            },
            {
                id: "age",
                accessorKey: "age",
                header: "Age",
                cell: (info) => info.getValue(),
            },
            {
                id: "dob",
                accessorKey: "dob",
                header: "Date of Birth",
                cell: (info) => info.getValue().toLocaleDateString(),
            },
            {
                id: "email",
                accessorKey: "email",
                header: "Email",
                cell: (info) => info.getValue(),
            },
            {
                id: "profession",
                accessorKey: "profession",
                header: "Profession",
                cell: (info) => info.getValue(),
            },
            {
                id: "is_active",
                accessorKey: "is_active",
                header: "Is Active",
                cell: (info) => (info.getValue() ? "Yes" : "No"),
            },
            {
                id: "company",
                accessorKey: "company",
                header: "Company",
                cell: (info) => info.getValue(),
            },
            columnHelper.accessor((row) => { var _a; return (_a = row.crm_data.find((crmData) => crmData.field.id === 12345)) === null || _a === void 0 ? void 0 : _a.value; }, {
                id: "12345",
                header: "Close Date",
                cell: (info) => info.getValue(),
            }),
        ];
    }, []);
    const [columnOrder, setColumnOrder] = useState(columns.map((c) => c.id));
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const accountsFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getPaginatedCrmData("Account", JSON.stringify({
                    table: "Account",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getAccounts", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: getCrmAccountName(account),
                    value: account.id.toString(),
                };
            }),
        };
    });
    const opportunitiesFetcher = (userQuery, ids, cursor) => __awaiter(void 0, void 0, void 0, function* () {
        const accountsQueryResponse = yield queryClient.fetchQuery({
            queryFn: () => {
                const filterConditions = [
                    {
                        where_condition: {
                            field: "name",
                            operator: WiserBackendQueryOperator.LIKE,
                            value: userQuery,
                        },
                    },
                ];
                if (ids) {
                    filterConditions.push({
                        where_condition: {
                            field: "id",
                            operator: WiserBackendQueryOperator.IN,
                            value: ids,
                        },
                    });
                }
                return getPaginatedCrmData("Opportunity", JSON.stringify({
                    table: "Opportunity",
                    order_by_fields: ["name"],
                    structured_filter: {
                        where_condition: {
                            operator: WiserBackendQueryOperator.AND,
                            subconditions: filterConditions,
                        },
                    },
                }), 1, 200);
            },
            queryKey: ["getOpportunities", cursor, userQuery, ids],
        });
        return {
            results: accountsQueryResponse.data.map((account) => {
                return {
                    label: account.opportunity_name,
                    value: account.id.toString(),
                };
            }),
        };
    });
    const filterConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxSubConditions: 3,
            referenceObjectFetcherMap: new Map([
                ["account_id", accountsFetcher],
                ["opportunity_id", opportunitiesFetcher],
            ]),
        };
    }, []);
    const sortRuleConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxConditions: 3,
        };
    }, []);
    const wiserBackendQueryConverter = useMemo(() => {
        return new WiserBackendQueryConverter(filterConfig);
    }, [filterConfig]);
    const initialFilter = useMemo(() => {
        const beFilter = convertSearchParamsToBackendFilter(searchParams);
        if (!beFilter) {
            return undefined;
        }
        return wiserBackendQueryConverter.convertBackendFilterToQueryFilter(beFilter);
    }, [searchParams]);
    const wiserBackendSortRuleConverter = useMemo(() => {
        return new WiserBackendSortRuleConverter(sortRuleConfig);
    }, [sortRuleConfig]);
    const initialSortRules = useMemo(() => {
        const beOrderBy = convertSearchParamsToBackendOrderBy(searchParams);
        if (!beOrderBy) {
            return undefined;
        }
        return wiserBackendSortRuleConverter.convertBackendOrderByToSortRules(beOrderBy);
    }, [searchParams]);
    const updateSearchParams = (key, value) => {
        const params = new URLSearchParams(searchParams);
        if (value) {
            params.set(key, value);
        }
        else {
            params.delete(key);
        }
        return params.toString();
    };
    const onFilterChange = (updatedFilter) => {
        console.log("UPDATED FILTER: ", updatedFilter);
        // Convert the filter to query param format
        const filterParam = updatedFilter
            ? convertBackendFilterToSearchParams(updatedFilter)
            : null;
        // Only update the 'filter' query param, leaving 'sort' untouched
        navigate(`?${updateSearchParams("filter", filterParam)}`);
    };
    const onSortRulesChange = (updatedRules) => {
        // Convert the sort rules to query param format
        const sortParam = updatedRules
            ? convertBackendOrderByClausesToSearchParams(updatedRules)
            : null;
        // Only update the 'sort' query param, leaving 'filter' untouched
        navigate(`?${updateSearchParams("order_by", sortParam)}`);
    };
    const { table } = useDataTable({
        data: data,
        columns: columns,
        pageCount: 100,
        enableAdvancedFilter: true,
        columnOrder,
        enableAdvancedSorting: true,
        onColumnOrderChange: setColumnOrder,
        tableName: "LiveCall",
    });
    return (_jsx("div", Object.assign({ className: "m-8" }, { children: _jsx(DataTable, Object.assign({ table: table, columnOrder: columnOrder, setColumnOrder: setColumnOrder }, { children: _jsx(DataTableToolbarV2, { table: table, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, onFiltersChange: onFilterChange, onSortRulesChange: onSortRulesChange, filterPanelTitle: "Show me rows", sortRuleBuilderPanelTitle: "Sort rows by", initialFilters: initialFilter, initialSortRules: initialSortRules, layouts: [], defaultTableLayout: {
                    table_name: "Test",
                    name: "Default view",
                    is_default: true,
                    is_public: false,
                    column_order: [],
                    columns: {},
                    query_string: "page=1&per_page=20",
                } }) })) })));
};
