import { useCallback, useMemo } from "react";
import { WiserBackendSortRuleConverter } from "../common/sort_rule_builder/backend_order_by_clause_converter";
import { convertBackendOrderByClausesToSearchParams, convertSearchParamsToBackendOrderBy, } from "../common/sort_rule_builder/search_params_utils";
import { updateSearchParams } from "../common/table_utils";
export function useSortRuleConfig(FIELDS) {
    const sortRuleConfig = useMemo(() => {
        return {
            fields: FIELDS,
            maxConditions: 3,
        };
    }, [FIELDS]);
    const wiserBackendSortRuleConverter = useMemo(() => {
        return new WiserBackendSortRuleConverter(sortRuleConfig);
    }, [sortRuleConfig]);
    return { sortRuleConfig, wiserBackendSortRuleConverter };
}
export function useInitialSortRules(searchParams, wiserBackendSortRuleConverter) {
    return useMemo(() => {
        const beOrderBy = convertSearchParamsToBackendOrderBy(searchParams);
        if (!beOrderBy) {
            return undefined;
        }
        return wiserBackendSortRuleConverter.convertBackendOrderByToSortRules(beOrderBy);
    }, [searchParams, wiserBackendSortRuleConverter]);
}
export function useSortRulesChange(navigate, searchParams) {
    return useCallback((updatedRules) => {
        const sortParam = updatedRules
            ? convertBackendOrderByClausesToSearchParams(updatedRules)
            : null;
        navigate(`?${updateSearchParams([
            { key: "order_by", value: sortParam },
            { key: "page", value: "1" },
        ], searchParams)}`);
    }, [navigate, searchParams]);
}
