import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState, } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { debounce } from "../common/debounce";
import classNames from "../class_names";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import useIntersectionObserver from "../common/intesection_observer";
import { LoadingSelectItems } from "../common/loaders";
import { Command, CommandGroup, CommandItem, CommandList } from "./command";
import { Popover, PopoverTrigger, PopoverContent } from "./popover";
const SelectedOptionPill = ({ option, handleDeselect, }) => {
    return (_jsxs("div", Object.assign({ className: "flex shrink-0 max-h-full px-2 py-[2px] items-center gap-2 text-sm text-wds-blue-4 bg-wds-blue-2 rounded overflow-hidden" }, { children: [option.label, _jsx("button", Object.assign({ type: "button", className: "shrink-0", onClick: () => handleDeselect(option) }, { children: _jsx(XMarkIcon, { className: "w-4 h-4" }) }))] }), option.value));
};
/**
 * Asynchronously loads items for a multi-select combobox.
 *
 * Provides a searchable dropdown experience without requiring all items upfront.
 *
 * Usage:
 *  <AsyncSearchMultiSelect
 *      dataFetcher={<your options fetcher>}
 *      onChange={<your callback for handling updated selected options>}
 *      selectedOptions={<controlled selected options from the parent>}
 *  />
 *
 *
 * @param id: Optional. If provided, this helps in managing type changes for the same
 *              component. Example: options changing from Account to Opportunity
 * @param dataFetcher: Async function to fetch select options.
 * @param onChange: Callback to invoke when selected options change
 * @param selectedOptions: Selected options
 * @param placeholder: Optional. Placeholder text to render in the input search
 * @param wrapPills: Optional. Boolean to indicate if the pills should be wrapped. Note that in this case
 *                      the input box will grow to wrap the selected options.
 * @param className: Optional. Additional classes for custom styling.
 */
export const AsyncSearchMultiSelect = (props) => {
    var _a, _b;
    const [searchQuery, setSearchQuery] = useState("");
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const queryClient = useQueryClient();
    const debouncedQuery = debounce(searchQuery, 400);
    const inputContainerRef = useRef(null);
    useEffect(() => {
        // This hook scrolls the input container to the far right if selected
        // pill wrapping is off.
        if (props.wrapPills) {
            return;
        }
        if (inputContainerRef.current) {
            inputContainerRef.current.scrollLeft =
                inputContainerRef.current.scrollWidth -
                    inputContainerRef.current.clientWidth;
        }
    }, [inputContainerRef, props.selectedOptions]);
    const { data: itemsFetchResponse, isLoading: itemsLoading, fetchNextPage, hasNextPage, isFetchingNextPage, } = useInfiniteQuery(["fetchSelectItems", debouncedQuery, (_a = props.id) !== null && _a !== void 0 ? _a : ""], ({ pageParam = null }) => props.dataFetcher(debouncedQuery, undefined, pageParam, queryClient), {
        getNextPageParam: (prevPage) => prevPage.next_cursor || null,
    });
    const selectItems = useMemo(() => {
        if (!itemsFetchResponse || !itemsFetchResponse.pages) {
            return [];
        }
        return itemsFetchResponse.pages.flatMap((page) => page.results);
    }, [itemsFetchResponse]);
    const loadMoreRef = useIntersectionObserver(() => {
        if (hasNextPage && fetchNextPage) {
            fetchNextPage();
        }
    }, { threshold: 1.0 });
    const handleOptionsChange = (options) => {
        props.onChange(options);
        setSearchQuery("");
    };
    const handleDeselect = (optionToDeselect) => {
        const updatedSelections = props.selectedOptions.filter((option) => option.value !== optionToDeselect.value);
        props.onChange(updatedSelections);
        setSearchQuery("");
    };
    const handleInputKeyDown = (event) => {
        if (event.key === "Backspace" &&
            searchQuery === "" &&
            props.selectedOptions.length) {
            handleDeselect(props.selectedOptions[props.selectedOptions.length - 1]);
        }
    };
    return (_jsxs(Popover, Object.assign({ open: isPopoverOpen, onOpenChange: setIsPopoverOpen }, { children: [_jsx(PopoverTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: classNames("flex w-80 h-8 py-0 px-4 gap-1 justify-between self-stretch", "bg-white border border-wds-gray-3 rounded-lg", props.wrapPills ? "h-auto" : "rounded-none rounded-r-lg") }, { children: _jsxs("div", Object.assign({ ref: inputContainerRef, className: classNames("flex py-1 gap-2", props.wrapPills ? "flex-wrap" : "flex-nowrap overflow-x-auto") }, { children: [props.selectedOptions.map((selectedOption) => (_jsx(SelectedOptionPill, { option: selectedOption, handleDeselect: handleDeselect }, selectedOption.value))), _jsx("input", { className: "p-1 text-sm text-gray-900 border-none bg-transparent focus:ring-0 focus:outline-none placeholder:text-wds-gray-4 rounded", placeholder: (_b = props.placeholder) !== null && _b !== void 0 ? _b : "", onChange: (e) => {
                                    setSearchQuery(e.target.value);
                                }, onKeyDown: handleInputKeyDown, value: searchQuery, onFocus: () => setIsPopoverOpen(true), onClick: (e) => {
                                    e.stopPropagation();
                                } })] })) })) })), _jsx(PopoverContent, Object.assign({ align: "start", side: "bottom", className: "w-full bg-white shadow-md rounded-md p-2 max-h-60 overflow-y-auto" }, { children: _jsx(Command, { children: _jsx(CommandList, { children: itemsLoading ? (_jsx(LoadingSelectItems, {})) : !selectItems || selectItems.length === 0 ? (_jsx("div", Object.assign({ className: "p-2 text-gray-700" }, { children: "Nothing found." }))) : (_jsxs(CommandGroup, { children: [selectItems.map((option) => {
                                    const isSelected = props.selectedOptions.some((opt) => opt.value === option.value);
                                    return (_jsxs(CommandItem, Object.assign({ className: "flex items-center justify-between px-4 py-2 cursor-pointer", onSelect: () => {
                                            if (isSelected) {
                                                handleDeselect(option);
                                                return;
                                            }
                                            handleOptionsChange([...props.selectedOptions, option]);
                                        } }, { children: [_jsx("span", { children: option.label }), isSelected && (_jsx(CheckIcon, { className: "w-4 h-4 text-blue-600 shrink-0" }))] }), option.value));
                                }), isFetchingNextPage && _jsx(LoadingSelectItems, {}), !isFetchingNextPage && hasNextPage && (_jsx("div", { ref: loadMoreRef, style: { height: "1px" } }))] })) }) }) }))] })));
};
