import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { useDataTable } from "../table_hooks/use_data_table";
import { DataTable } from "../components/data_table";
import { getColumnIdColumnNameMap, getColumns } from "./calls_table_columns";
import { CallsTableFloatingBar } from "./calls_table_floating_bar";
import { CallsTableToolbarActions } from "./calls_table_toolbar_actions";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { DataTableToolbarV2 } from "../components/data_table_toolbar_v2";
import { FIELDS, SORTABLE_FIELDS } from "./calls_table_filters";
import { useFilterChange, useFilterConfig, useInitialFilter, } from "../table_hooks/use_filters";
import { useSortRuleConfig, useInitialSortRules, useSortRulesChange, } from "../table_hooks/use_sort";
import { Drawer, DrawerContent, DrawerTitle, } from "../components/drawer_dialog";
import CallPage from "../home/call_v3";
import { useQuery, useQueryClient } from "react-query";
import { getAppIntegrationData } from "../common/endpoints";
import { getColumnOrder, useMatchingLayout, } from "../components/data_table_toolbar_hooks";
export function CallsTable(data) {
    // Feature flags for showcasing some additional features. Feel free to remove them.
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { callIdParam } = useParams();
    const hasCallIdParam = callIdParam !== undefined;
    const calls = data.calls;
    const total = data.total;
    const [rawSearchParams, setSearchParams] = useSearchParams();
    const pageSize = parseInt(rawSearchParams.get("per_page") || "20");
    const pageCount = Math.ceil(total / pageSize);
    const { data: appIntegrationData } = useQuery({
        queryKey: ["appIntegrationData", data.tenant.id],
        queryFn: getAppIntegrationData,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    // Memoize the columns so they don't re-render on every render
    const columns = React.useMemo(() => getColumns(data.tenant, data.playbookItemGroups, data.searchParams, data.filterQuery, queryClient, appIntegrationData), [data.playbookItemGroups, appIntegrationData]);
    const matchingLayout = useMatchingLayout(data.layouts, data.searchParams);
    const [columnOrder, setColumnOrder] = React.useState(getColumnOrder(columns, matchingLayout));
    const columnIdMap = React.useMemo(() => getColumnIdColumnNameMap(), []);
    const playbook_item_names = data.playbookItemGroups.map((item) => item.default_item.name);
    const { filterConfig, wiserBackendQueryConverter } = useFilterConfig(FIELDS);
    const { sortRuleConfig, wiserBackendSortRuleConverter } = useSortRuleConfig(SORTABLE_FIELDS);
    const initialFilter = useInitialFilter(data.searchParams, wiserBackendQueryConverter);
    const initialSortRules = useInitialSortRules(data.searchParams, wiserBackendSortRuleConverter);
    const handleFilterChange = useFilterChange(navigate, data.searchParams);
    const handleSortRulesChange = useSortRulesChange(navigate, data.searchParams);
    const { table } = useDataTable({
        data: calls,
        columns,
        pageCount,
        initialState: {
            columnPinning: { right: ["actions"], left: ["select", "name"] },
            columnVisibility: Object.assign({ call_state: false, call_state_with_reason: false, call_source: false, meeting_host: false, participants: false, call_duration_minutes: false, post_call_all_slack_items_sent: false, post_call_any_slack_item_sent: false, post_call_crm_updates_mapped: false, post_call_crm_updates_pushed: false, playbook_content: false, description: false, account_id: false, opportunity_id: false }, Object.fromEntries(playbook_item_names.map((name) => [name, false]))),
        },
        columnOrder,
        onColumnOrderChange: setColumnOrder,
        enableAdvancedFilter: true,
        enableAdvancedSorting: true,
        // For remembering the previous row selection on page change
        getRowId: (originalRow, index) => `${originalRow.id}-${index}`,
        tableName: "calls",
    });
    const renderNewToolBar = () => {
        return (_jsx(DataTableToolbarV2, { table: table, columnIdMap: columnIdMap, layouts: data.layouts, filterConfig: filterConfig, sortRuleConfig: sortRuleConfig, defaultTableLayout: DEFAULT_TABLE_LAYOUT, exportComponent: _jsx(CallsTableToolbarActions, { table: table }), onFiltersChange: handleFilterChange, onSortRulesChange: handleSortRulesChange, filterPanelTitle: "Show me calls", sortRuleBuilderPanelTitle: "Sort calls by", initialFilters: initialFilter, initialSortRules: initialSortRules, showSearchBar: true, searchQueryValue: data.searchQuery, onSearchQueryChange: data.setSearchQuery }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, Object.assign({ table: table, floatingBar: _jsx(CallsTableFloatingBar, { table: table }), columnOrder: columnOrder, setColumnOrder: setColumnOrder }, { children: renderNewToolBar() })), _jsx(Drawer, Object.assign({ open: hasCallIdParam, onOpenChange: (isOpen) => {
                    if (!isOpen)
                        navigate("/calls" + window.location.search);
                } }, { children: _jsxs(DrawerContent, Object.assign({ className: "h-full sm:max-w-none p-0 sm:w-5/6", hideCloseButton: true }, { children: [_jsx(DrawerTitle, Object.assign({ className: "hidden" }, { children: "Call Details" })), _jsx(CallPage, { updateSelectedCall: () => { }, onClose: () => navigate("/calls" + window.location.search) })] })) }))] }));
}
