import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DragDropContext, Draggable, Droppable, } from "react-beautiful-dnd";
export const AccountBlocks = ({ accountBlocksToRender, blocksRefCallback, staticBlocksCount, reorderResearchBlocks, }) => {
    const onDragEnd = (result) => {
        const { source, destination } = result;
        // If no destination, do nothing
        if (!destination) {
            return;
        }
        const blocksToReorder = accountBlocksToRender.slice(staticBlocksCount);
        const [draggedBlock] = blocksToReorder.splice(source.index, 1);
        blocksToReorder.splice(destination.index, 0, draggedBlock);
        reorderResearchBlocks(blocksToReorder.map((block) => Number(block.id)));
    };
    return (_jsx(DragDropContext, Object.assign({ onDragEnd: onDragEnd }, { children: _jsx(Droppable, Object.assign({ droppableId: "account-blocks" }, { children: (provided) => (_jsxs("div", Object.assign({ className: "flex w-full items-start flex-col gap-4" }, provided.droppableProps, { ref: provided.innerRef }, { children: [accountBlocksToRender
                        .slice(0, staticBlocksCount)
                        .map((accountBlock) => {
                        return (_jsx("div", Object.assign({ className: "flex items-start self-stretch scroll-mt-4 link-styles", ref: (divContainer) => {
                                blocksRefCallback(accountBlock.id.toString(), divContainer);
                            } }, { children: accountBlock.renderer({ dragHandleProps: null }) })));
                    }), accountBlocksToRender
                        .slice(staticBlocksCount)
                        .map((accountBlock, index) => {
                        return (_jsx(Draggable, Object.assign({ draggableId: accountBlock.id, index: index }, { children: (provided) => (_jsx("div", Object.assign({ className: "flex items-start self-stretch scroll-mt-4 link-styles", ref: (divContainer) => {
                                    blocksRefCallback(accountBlock.id, divContainer);
                                    provided.innerRef(divContainer);
                                } }, provided.draggableProps, { children: accountBlock.renderer({
                                    dragHandleProps: provided.dragHandleProps,
                                }) }))) }), accountBlock.id));
                    }), provided.placeholder, _jsx("div", { className: "h-60" })] }))) })) })));
};
