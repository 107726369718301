import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import { useState, createContext, useContext, useEffect, useRef, } from "react";
import classNames from "../class_names";
// Define context to hold the expand/collapse logic
const ExpandablePostCallContext = createContext(null);
export const useExpandablePostCall = () => {
    const context = useContext(ExpandablePostCallContext);
    if (!context) {
        throw new Error("useExpandablePostCall must be used within a ExpandablePostCallProvider");
    }
    return context;
};
// ExpandablePostCall Component
export const ExpandablePostCall = ({ showLessContent, showMoreContent, alwaysShowExpandButton, }) => {
    const [showMore, setShowMore] = useState(false);
    const [isTruncated, setIsTruncated] = useState(false);
    const textBoxRef = useRef(null);
    useEffect(() => {
        if (textBoxRef.current) {
            const { scrollHeight, clientHeight } = textBoxRef.current;
            setIsTruncated(scrollHeight > clientHeight);
        }
    }, [showLessContent, showMore]);
    return (_jsx(ExpandablePostCallContext.Provider, Object.assign({ value: { showMore, setShowMore, textBoxRef } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col w-full" }, { children: [_jsx("div", Object.assign({ className: classNames("relative", showMore ? "" : "line-clamp-4") }, { children: showMore ? showMoreContent : showLessContent })), (isTruncated || showMore || alwaysShowExpandButton) && (_jsxs("button", Object.assign({ className: "text-wds-gray-6 w-full flex items-center font-bold mt-2 space-x-1 text-sm hover:bg-wds-gray-1", onClick: () => setShowMore(!showMore) }, { children: [showMore ? "Show less" : "Show more", showMore ? (_jsx(ChevronUpIcon, { className: "w-4 h-4 text-wds-gray-6" })) : (_jsx(ChevronRightIcon, { className: "w-4 h-4 text-wds-gray-6" }))] })))] })) })));
};
