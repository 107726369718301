import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { InformationCircleIcon, VideoCameraIcon, } from "@heroicons/react/24/outline";
import { PlaybackTimelineControls } from "./video_playback_timeline";
import { getCallRecordingDetailsPublic } from "./common/endpoints";
import { useQuery } from "react-query";
const PublicCallRecordingPage = ({ expiringLinkId, }) => {
    const { data, isLoading } = useQuery({
        queryKey: ["callRecordingDetailsPublic", expiringLinkId],
        queryFn: () => getCallRecordingDetailsPublic(expiringLinkId),
    });
    const initialVideoTimeSec = new URLSearchParams(window.location.search).get("t");
    const [videoLengthMsec, setVideoLengthMsec] = useState(0);
    const [currentVideoTime, setCurrentVideoTime] = useState(initialVideoTimeSec ? Number(initialVideoTimeSec) * 1000 : 0);
    const videoRef = useRef(null);
    useEffect(() => {
        if (!videoRef.current)
            return;
        if (Math.abs(videoRef.current.currentTime * 1000 - currentVideoTime) < 1000)
            return;
        videoRef.current.currentTime = currentVideoTime / 1000;
    }, [currentVideoTime, videoRef.current]);
    const VideoComponent = useMemo(() => {
        if (!(data === null || data === void 0 ? void 0 : data.video_url))
            return (_jsx("div", Object.assign({ className: "w-[2/3] flex items-center justify-center w-full h-[256px] lg:h-[400px] bg-wds-gray-3 rounded-xl" }, { children: _jsx(VideoCameraIcon, { className: "h-24 w-24 text-wds-gray-5 fill-white stroke-0" }) })));
        return (_jsx("video", { ref: videoRef, className: "video-js vjs-big-play-centered rounded-xl", preload: "auto", src: data.video_url, controls: true, onLoadedMetadata: (e) => {
                const duration = e.currentTarget.duration;
                if (duration === 0)
                    return;
                if (Number.isNaN(duration))
                    return;
                setVideoLengthMsec(duration * 1000);
            }, onTimeUpdate: (e) => {
                setCurrentVideoTime(e.currentTarget.currentTime * 1000);
            }, onSeeked: (e) => {
                setCurrentVideoTime(e.currentTarget.currentTime * 1000);
            } }));
    }, [data === null || data === void 0 ? void 0 : data.video_url]);
    if (isLoading)
        return _jsx("div", { children: "Loading..." });
    return (_jsxs("div", Object.assign({ className: "w-2/3 mx-auto mt-10 flex flex-col items-center gap-4" }, { children: [_jsx("div", Object.assign({ className: "text-2xl font-bold" }, { children: data === null || data === void 0 ? void 0 : data.call_name })), VideoComponent, (data === null || data === void 0 ? void 0 : data.video_url) ? (_jsx(PlaybackTimelineControls, { timeline: data.speaker_timeline, endMsec: videoLengthMsec, currentMsec: currentVideoTime, setCurrentMsec: setCurrentVideoTime })) : (_jsx("div", Object.assign({ className: "w-full h-24 flex items-center justify-center border border-wds-blue-3 p-4 bg-white rounded-lg border-l-4 flex-inline" }, { children: _jsxs("div", Object.assign({ className: "flex flex-row items-start gap-2" }, { children: [_jsx(InformationCircleIcon, { className: "h-6 w-6 text-white fill-wds-blue-3 stroke-white" }), "The call recording is not available."] })) })))] })));
};
export default PublicCallRecordingPage;
