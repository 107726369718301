var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "../components/badge";
import { Checkbox } from "../components/checkbox";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import CallTimeDisplay from "../home/call_time_display";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, } from "../components/tooltip";
import { Button } from "../components/button";
import { Link } from "react-router-dom";
import { getMeetingType, getUserReadableStatus, } from "../video_playback_status_utils";
import ReactMarkdown from "react-markdown";
import { IMAGE_SRCS, MEETING_TEXTS } from "../video_with_transcript_constants";
import { getConnectedCrmLogo } from "../common/crm_logo";
import { getExternalCrmObjectLink } from "../crm/common/external_crm_link";
import { CrmObjectReferenceSwitcher } from "../crm/crm_object_reference_switcher";
import { accountsFetcher, opportunitiesFetcher } from "./calls_table_fetchers";
import { setCallAccountAndOpportunity } from "../common/endpoints";
export function getColumns(tenant, playbookItemGroups, searchParams, filterQuery, queryClient, appIntegrationData) {
    // This dedupes the playbook items by name
    const newPlaybookItems = Object.values(playbookItemGroups.reduce((acc, item) => {
        acc[item.default_item.name] = item;
        return acc;
    }, {}));
    const getConnectedCrmColumns = () => {
        if (!tenant.connected_crm) {
            return [];
        }
        return [
            {
                id: "account_id",
                accessorKey: "account_id",
                header: "CRM: Account",
                cell: ({ row }) => {
                    var _a, _b;
                    if (tenant.connected_crm && ((_a = row.original.account) === null || _a === void 0 ? void 0 : _a.external_id)) {
                        return (_jsx("div", Object.assign({ className: "link-styles" }, { children: _jsxs("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], "Account", (_b = row.original.account) === null || _b === void 0 ? void 0 : _b.external_id), target: "_blank", rel: "noopener noreferrer", className: "flex flex-row" }, { children: [_jsx("img", { className: "h-5 w-5 mr-2", src: getConnectedCrmLogo(tenant.connected_crm) }), row.original.account_name] })) })));
                    }
                    return null;
                },
                accessorFn: (row) => row.account_id,
            },
            {
                id: "opportunity_id",
                accessorKey: "opportunity_id",
                header: "CRM: Opportunity",
                cell: ({ row }) => {
                    var _a, _b;
                    if (tenant.connected_crm && ((_a = row.original.opportunity) === null || _a === void 0 ? void 0 : _a.external_id)) {
                        return (_jsx("div", Object.assign({ className: "link-styles" }, { children: _jsxs("a", Object.assign({ href: getExternalCrmObjectLink(tenant.connected_crm, appIntegrationData !== null && appIntegrationData !== void 0 ? appIntegrationData : [], "Opportunity", (_b = row.original.opportunity) === null || _b === void 0 ? void 0 : _b.external_id), target: "_blank", rel: "noopener noreferrer", className: "flex flex-row" }, { children: [_jsx("img", { className: "h-5 w-5 mr-2", src: getConnectedCrmLogo(tenant.connected_crm) }), row.original.opportunity_name] })) })));
                    }
                    return null;
                },
                accessorFn: (row) => row.opportunity_id,
            },
        ];
    };
    const playbookTrackerColumns = newPlaybookItems.map((playbookItemGroup) => {
        return {
            id: playbookItemGroup.default_item.name,
            accessorKey: playbookItemGroup.default_item.name,
            header: playbookItemGroup.default_item.name,
            cell: ({ row }) => {
                const playbookItemNotes = JSON.parse(row.getValue("playbook_content") || '{"content": []}');
                const filteredNotes = playbookItemNotes.content.flatMap((notesGroup) => notesGroup.filter((playbookItemNote) => playbookItemNote.name === playbookItemGroup.default_item.name));
                const res = filteredNotes.map((note) => note.content).join("");
                return (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx(ReactMarkdown, { children: res }) })) })), _jsx(TooltipContent, { children: _jsx(ReactMarkdown, { children: res }) })] }) }));
            },
        };
    });
    return [
        {
            id: "select",
            header: ({ table }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: table.getIsAllPageRowsSelected() ||
                        (table.getIsSomePageRowsSelected() && "indeterminate"), onCheckedChange: (value) => table.toggleAllPageRowsSelected(!!value), "aria-label": "Select all", className: "translate-y-0.5" }) }))),
            cell: ({ row }) => (_jsx("div", Object.assign({ className: "pr-2" }, { children: _jsx(Checkbox, { checked: row.getIsSelected(), onCheckedChange: (value) => row.toggleSelected(!!value), "aria-label": "Select row", className: "translate-y-0.5" }) }))),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.id,
        },
        {
            id: "name",
            accessorKey: "name",
            header: "Call Name",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/calls/${row.original.id}` + window.location.search, rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: row.getValue("name") }) })) })) })), _jsx(TooltipContent, { children: row.getValue("name") })] }) })),
            enableSorting: false,
            enableHiding: false,
            accessorFn: (row) => row.name,
        },
        {
            id: "call_type",
            accessorKey: "call_type",
            header: "Call type",
            cell: ({ row }) => {
                const call_type = ["Internal", "External"].find((call_type) => call_type === row.original.call_type);
                return (_jsx("div", Object.assign({ className: "flex space-x-2" }, { children: call_type && _jsx(Badge, Object.assign({ variant: "outline" }, { children: call_type })) })));
            },
            accessorFn: (row) => row.call_type,
        },
        {
            id: "wiser_joined",
            accessorKey: "wiser_joined",
            header: "Wiser joined",
            cell: ({ row }) => {
                if (!row.original.wiser_joined)
                    return null;
                return (_jsxs("div", Object.assign({ className: "flex w-[6.25rem] items-center" }, { children: [_jsx(CheckCircleIcon, { className: "mr-2 h-4 w-4 text-muted-foreground", "aria-hidden": "true" }), _jsx("span", Object.assign({ className: "capitalize" }, { children: "Recorded" }))] })));
            },
            filterFn: (row, id, value) => {
                return Array.isArray(value) && value.includes(row.getValue(id));
            },
            accessorFn: (row) => row.wiser_joined,
        },
        {
            id: "call_state",
            accessorKey: "call_state",
            header: "Call state",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: getUserReadableStatus(cell.getValue(), "Predates status tracking", "Recording Successful") })) })] }) })),
            accessorFn: (row) => row.call_state,
        },
        {
            id: "call_time",
            accessorKey: "call_time",
            header: "Call time (local)",
            cell: ({ row, cell }) => (_jsx("div", Object.assign({ className: "w-[150px]" }, { children: _jsx(CallTimeDisplay, { call: row.original, showDate: true, showCalendarIcon: false }) }))),
            accessorFn: (row) => row.call_time,
        },
        {
            id: "playbook_content",
            accessorKey: "playbook_content",
            header: "Playbook content",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View tracker notes" }) })) }))),
            enableHiding: false,
        },
        {
            id: "playbook_items",
            accessorKey: "playbook_items",
            header: "Tracker notes",
            // This cell is rendered as a collapsed JSON. Once design mocks are there rework on this.
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#trackers`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx(Button, Object.assign({ variant: "link", size: "sm" }, { children: "View tracker notes" })) })) })), _jsx(TooltipContent, { children: _jsx("ul", { children: cell.getValue().map((playbookItemName) => (_jsx("li", { children: playbookItemName }, playbookItemName))) }) })] }) })),
            accessorFn: (row) => row.playbook_items,
        },
        {
            id: "description",
            accessorKey: "description",
            header: "Description",
            cell: ({ cell }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: _jsx("div", { dangerouslySetInnerHTML: {
                                        __html: cell.getValue(),
                                    } }) })) })] }) })),
            accessorFn: (row) => row.description,
        },
        {
            id: "call_source",
            accessorKey: "call_source",
            header: "Call source",
            cell: ({ row }) => (_jsxs("div", Object.assign({ className: "flex justify-start items-center w-full text-wds-gray-6 text-sm" }, { children: [_jsx("img", { src: IMAGE_SRCS.get(getMeetingType(row.original)), className: "h-4 w-4 mx-2" }), MEETING_TEXTS.get(getMeetingType(row.original))] }))),
        },
        {
            id: "participants",
            accessorKey: "participants",
            header: "Participants",
            cell: ({ row }) => (_jsx(TooltipProvider, { children: _jsxs(Tooltip, { children: [_jsx(TooltipTrigger, Object.assign({ asChild: true }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: row.original.participants
                                    .filter((participant) => participant.email !== row.original.host_email)
                                    .map((participant) => (_jsx("div", { children: participant.email }, participant.email))) })) })), _jsx(TooltipContent, { children: _jsx("div", Object.assign({ className: "link-styles" }, { children: row.original.participants
                                    .filter((participant) => participant.email !== row.original.host_email)
                                    .map((participant) => (_jsx("div", { children: participant.email }, participant.email))) })) })] }) })),
            accessorFn: (row) => row.description,
        },
        {
            id: "call_duration_minutes",
            accessorKey: "call_duration_minutes",
            header: "Call duration minutes",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            id: "post_call_all_slack_items_sent",
            accessorKey: "post_call_all_slack_items_sent",
            header: "All slack items sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            id: "post_call_any_slack_item_sent",
            accessorKey: "post_call_any_slack_item_sent",
            header: "Any slack item sent?",
            cell: ({ cell }) => (cell.getValue() ? "Yes" : "No"),
        },
        {
            id: "post_call_crm_updates_mapped",
            accessorKey: "post_call_crm_updates_mapped",
            header: "CRM updates mapped",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            id: "post_call_crm_updates_pushed",
            accessorKey: "post_call_crm_updates_pushed",
            header: "CRM updates processed",
            cell: ({ cell }) => cell.getValue(),
        },
        {
            id: "meeting_host",
            accessorKey: "meeting_host",
            header: "Meeting host",
            cell: ({ row }) => row.original.host_email,
        },
        {
            id: "account_name",
            accessorKey: "account_name",
            header: "Account",
            cell: ({ cell, row }) => {
                var _a;
                return (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                        columnId: "account",
                        objectsFetcher: (userQuery, cursor) => __awaiter(this, void 0, void 0, function* () {
                            return yield accountsFetcher(userQuery, 
                            /* ids= */ undefined, cursor, queryClient);
                        }),
                    }, selectedOption: row.original.account
                        ? {
                            label: (_a = row.original.account.account_name) !== null && _a !== void 0 ? _a : row.original.account.name,
                            value: row.original.account.id.toString(),
                        }
                        : undefined, onSelectedOptionChange: (option) => __awaiter(this, void 0, void 0, function* () {
                        yield setCallAccountAndOpportunity(row.original.id, Number(option.value));
                        const per_page = parseInt(searchParams.get("per_page") || "20") || 20;
                        const page = parseInt(searchParams.get("page") || "1") || 1;
                        queryClient.invalidateQueries([
                            "callsTable",
                            filterQuery,
                            page,
                            per_page,
                        ]);
                    }) }));
            },
            accessorFn: (row) => row.account_name,
        },
        {
            id: "opportunity_name",
            accessorKey: "opportunity_name",
            header: "Opportunity",
            cell: ({ cell, row }) => (_jsx(CrmObjectReferenceSwitcher, { columnObjectsFetcher: {
                    columnId: "opportunity",
                    objectsFetcher: (userQuery, cursor) => __awaiter(this, void 0, void 0, function* () {
                        return yield opportunitiesFetcher(userQuery, 
                        /* ids= */ undefined, cursor, queryClient);
                    }),
                }, selectedOption: row.original.opportunity
                    ? {
                        label: row.original.opportunity.opportunity_name,
                        value: row.original.opportunity.id.toString(),
                    }
                    : undefined, onSelectedOptionChange: (option) => __awaiter(this, void 0, void 0, function* () {
                    yield setCallAccountAndOpportunity(row.original.id, 
                    /* accountId= */ undefined, Number(option.value));
                    const per_page = parseInt(searchParams.get("per_page") || "20") || 20;
                    const page = parseInt(searchParams.get("page") || "1") || 1;
                    queryClient.invalidateQueries([
                        "callsTable",
                        filterQuery,
                        page,
                        per_page,
                    ]);
                }) })),
            accessorFn: (row) => row.opportunity_name,
        },
        ...getConnectedCrmColumns(),
        {
            id: "transcripts",
            accessorKey: "transcripts",
            header: "Transcripts",
            cell: ({ cell }) => (_jsx(Link, Object.assign({ to: `/post_call/${cell.row.original.id}#transcript`, target: "_blank", rel: "noopener noreferrer" }, { children: _jsx("div", Object.assign({ className: "overflow-hidden text-ellipsis line-clamp-2 link-styles" }, { children: _jsx("a", { children: "View transcripts" }) })) }))),
            accessorFn: (row) => row.transcripts,
        },
        ...playbookTrackerColumns,
    ];
}
export function getColumnIdColumnNameMap() {
    // Returns list of column id and column name
    // Only for those that have "React.Node" type header
    return {
        select: "Select",
        name: "Call Name",
        call_type: "Call type",
        call_time: "Call time",
        wiser_joined: "Wiser joined",
        playbook_items: "Tracker notes",
        account_name: "Account",
        opportunity_name: "Opportunity",
    };
}
