import { jsx as _jsx } from "react/jsx-runtime";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import PublicCallRecordingPage from "./public_call_recording_page";
import NotFound404 from "./NotFound404";
import { publicGetExpiringLink } from "./common/endpoints";
const ExpiringPublicLinkPage = () => {
    var _a;
    const { linkId } = useParams();
    if (!linkId) {
        return null;
    }
    const expiringLink = useQuery({
        queryKey: ["expiringLink", linkId],
        queryFn: () => publicGetExpiringLink(linkId),
    });
    if (expiringLink.isLoading) {
        return _jsx("div", { children: "Loading..." });
    }
    if (!((_a = expiringLink.data) === null || _a === void 0 ? void 0 : _a.link_id)) {
        return _jsx(NotFound404, {});
    }
    if (expiringLink.data.link_type === "CallRecording") {
        return (_jsx(PublicCallRecordingPage, { expiringLinkId: expiringLink.data.link_id }));
    }
    return _jsx(NotFound404, {});
};
export default ExpiringPublicLinkPage;
