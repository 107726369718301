var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import BaseModal from "../base_modal";
import { createCallAndSetParticipants, getLiveCallById, } from "../common/endpoints";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
export const AddCallDialog = (props) => {
    const [addCallOpen, setAddCallOpen] = useState(false);
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const doCreateCall = (name, participants, zoom_meeting_id, zoom_passcode, time) => __awaiter(void 0, void 0, void 0, function* () {
        const response = yield queryClient.fetchQuery(["createCall", name, participants, zoom_meeting_id, zoom_passcode, time], () => __awaiter(void 0, void 0, void 0, function* () {
            const callCreated = yield createCallAndSetParticipants(name, participants, zoom_meeting_id, zoom_passcode, time);
            const call = yield getLiveCallById(callCreated.id);
            props.updateSelectedCall(call);
            setAddCallOpen(false);
            navigate(`/home/call/${callCreated.id}`);
        }));
    });
    const fieldsToMap = (fields) => {
        let map = new Map();
        for (let i = 0; i < fields.length; i++) {
            map.set(fields[i].name, fields[i].value);
        }
        return map;
    };
    return (_jsxs("div", { children: [_jsx("button", Object.assign({ className: "p-2 rounded-full justify-center items-center gap-2 flex hover:bg-blue-100", onClick: () => setAddCallOpen(true) }, { children: _jsx(PlusIcon, { className: "h-5 w-5 text-wds-gray-5" }) })), _jsx(BaseModal, { title: "Add Call", description: "A call is an upcoming meeting with a set of participants.", open: addCallOpen, setOpen: setAddCallOpen, reactQuery: true, submitCallback: (fields) => __awaiter(void 0, void 0, void 0, function* () {
                    let fieldMap = fieldsToMap(fields);
                    yield doCreateCall(fieldMap.get("Name"), fieldMap.get("Participants"), Number(fieldMap.get("Zoom Meeting Id")), fieldMap.get("Zoom Passcode"), fieldMap.get("Time"));
                }), fields: [
                    {
                        name: "Name",
                        type: "text",
                        value: "",
                        required: true,
                        placeholder: "Name of this call",
                    },
                    {
                        name: "Participants",
                        type: "text",
                        value: "",
                        placeholder: "Participants",
                    },
                    {
                        name: "Zoom Meeting Id",
                        type: "number",
                        value: "",
                        placeholder: "Zoom Meeting Id",
                    },
                    {
                        name: "Zoom Passcode",
                        type: "text",
                        value: "",
                        placeholder: "Zoom Passcode",
                    },
                    {
                        name: "Time",
                        type: "datetime-local",
                        value: "",
                        required: true,
                        placeholder: "Start time",
                    },
                ], submitText: "Start Call" })] }));
};
