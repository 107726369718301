import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DataTableSkeleton } from "../components/data_table_skeleton";
import { CallsTable } from "./calls_table";
import { convertNewSearchParamsIntoFilterQuery } from "./convert_search_params_to_filter_query";
import { useQuery, useQueryClient } from "react-query";
import { getActiveTenant, getCallsForTable, getPlaybookItemGroups, } from "../common/endpoints";
import { useEffect, useState } from "react";
import { DEFAULT_TABLE_LAYOUT } from "../settings/constants";
import { debounce } from "../common/debounce";
import { useCustomSearchParams } from "../crm/use_memoised_search_params";
export default function IndexPage() {
    const [searchQuery, setSearchQuery] = useState("");
    const debouncedQuery = debounce(searchQuery, 400);
    const queryClient = useQueryClient();
    const { searchParams, layouts, isLoading: layoutsLoading, isError: layoutsError, } = useCustomSearchParams("calls");
    const filterQuery = convertNewSearchParamsIntoFilterQuery(searchParams, "LiveCall", debouncedQuery);
    const page = parseInt(searchParams.get("page") || "1") || 1;
    const perPage = parseInt(searchParams.get("per_page") || "20") || 20;
    const { data, isLoading, isError } = useQuery(["callsTable", filterQuery, page, perPage], () => getCallsForTable(filterQuery, page, perPage), {
        refetchOnWindowFocus: false,
        keepPreviousData: true, // Does not re-render the page untill data is loaded
    });
    const { data: playbookItemGroups } = useQuery("getPlaybookItemGroups", getPlaybookItemGroups);
    const { data: activeTenant } = useQuery({
        queryKey: ["activeTenant"],
        queryFn: getActiveTenant,
    });
    const calls = (data === null || data === void 0 ? void 0 : data.data) || [];
    const total = (data === null || data === void 0 ? void 0 : data.total) || 0;
    const wiser_joined_count = (data === null || data === void 0 ? void 0 : data.wiser_joined_count) || 0;
    useEffect(() => {
        if (!layoutsLoading &&
            !layoutsError &&
            (!layouts || layouts.length === 0)) {
            queryClient.setQueryData(["getTableLayouts", "calls"], [DEFAULT_TABLE_LAYOUT]);
        }
    }, [layoutsLoading, layoutsError, layouts]);
    return (_jsxs("div", Object.assign({ className: "gap-2 p-4 bg-wds-gray-1 h-screen" }, { children: [_jsx("div", Object.assign({ className: "w-60 text-black text-3xl p-2" }, { children: "Calls" })), !data ||
                isLoading ||
                !playbookItemGroups ||
                layoutsLoading ||
                !activeTenant ||
                !layouts ||
                layouts.length === 0 ? (_jsx(DataTableSkeleton, { columnCount: 5, searchableColumnCount: 1, filterableColumnCount: 2, cellWidths: ["10rem", "40rem", "12rem", "12rem", "8rem"], shrinkZero: true })) : (_jsx(CallsTable, { calls: calls, total: total, wiser_joined_count: wiser_joined_count, playbookItemGroups: playbookItemGroups, layouts: layouts, searchQuery: searchQuery, setSearchQuery: setSearchQuery, tenant: activeTenant, searchParams: searchParams, filterQuery: filterQuery }))] })));
}
