import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// This file defines the per-speaker timelines to show below a video.
// These can be synced via props with a video playback.
import { useState } from "react";
import classNames from "./class_names";
import { formatCallTime } from "./strings";
import Draggable from "react-draggable";
const Segment = ({ start, end, color }) => {
    const width = end - start;
    return (_jsx("div", { className: classNames("absolute rounded h-2", color), style: { left: `${start}%`, width: `${width}%` } }));
};
const SingleTimeline = ({ label, segments, color, onClick, }) => {
    return (_jsxs("div", Object.assign({ className: "relative flex flex-col h-8 my-1" }, { children: [_jsx("div", Object.assign({ className: "flex justify-start text-sm" }, { children: label })), _jsx("div", Object.assign({ className: "flex bg-wds-gray-3 h-2", onClick: onClick }, { children: segments.map((segment, segIndex) => (_jsx(Segment, { start: segment.start_fraction * 100, end: segment.end_fraction * 100, color: color }, segIndex))) }))] })));
};
const TIMELINE_COLORS = [
    "bg-indigo-500",
    "bg-pink-500",
    "bg-wds-yellow-extended-500",
    "bg-wds-green-extended-500",
    "bg-wds-cyan-extended-500",
];
export const PlaybackTimelineControls = ({ timeline, endMsec, currentMsec, setCurrentMsec, }) => {
    var _a;
    const [sliderPosition, setSliderPosition] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const handleDrag = (e, data) => {
        setSliderPosition(data.x);
        setIsDragging(true);
    };
    const currentTimeDisplay = formatCallTime(0, currentMsec) + " / " + formatCallTime(0, endMsec);
    const parentWidth = (_a = document.querySelector(".timeline-container")) === null || _a === void 0 ? void 0 : _a.clientWidth;
    return (_jsxs("div", Object.assign({ className: "relative w-full timeline-container -mx-4", onClick: (event) => {
            var _a;
            const element = event.nativeEvent.target;
            if (((_a = element.parentElement) === null || _a === void 0 ? void 0 : _a.offsetLeft) !== 0) {
                // This detects clicks on the slider, which is a child of the
                // timeline container. We don't want to update the time when the
                // user clicks on the slider.
                return;
            }
            // This handles cases where the user clicks on a timeline displaying
            // speaking segments -- the speaking segments have a smaller width but
            // offset relative to the start of the parent (the full timeline div).
            const offset = element.offsetLeft + event.nativeEvent.offsetX;
            const newTime = (offset / (parentWidth !== null && parentWidth !== void 0 ? parentWidth : 100)) * endMsec;
            setCurrentMsec(newTime);
        } }, { children: [_jsx(SingleTimeline, { label: currentTimeDisplay, segments: [
                    {
                        start_fraction: 0,
                        end_fraction: currentMsec / endMsec,
                    },
                ], color: "bg-wds-gray-4" }), timeline.map((speakerTimeline, index) => (_jsx(SingleTimeline, { label: speakerTimeline.speaker, segments: speakerTimeline.segments, color: TIMELINE_COLORS[index % TIMELINE_COLORS.length] }, index))), _jsx(Draggable, Object.assign({ axis: "x", bounds: "parent", position: {
                    x: isDragging
                        ? sliderPosition
                        : (currentMsec / endMsec) * (parentWidth !== null && parentWidth !== void 0 ? parentWidth : 100),
                    y: 0,
                }, onDrag: handleDrag, onStop: (event) => {
                    if (!isDragging) {
                        // This was just a click on the slider, not a drag.
                        // When this happens, we should not run the code in the
                        // parent to set the position, because it hasn't changed.
                        event.stopPropagation();
                        return;
                    }
                    setIsDragging(false);
                    if (!parentWidth) {
                        return;
                    }
                    const newTime = (sliderPosition / parentWidth) * endMsec;
                    setCurrentMsec(newTime);
                } }, { children: _jsxs("div", Object.assign({ className: "absolute flex flex-col top-0 left-0 h-full px-2 w-2 cursor-grab items-center -mx-2" }, { children: [_jsx("div", { className: "flex bg-wds-blue-3 h-6 w-4 mt-5 rounded-full" }), _jsx("div", { className: "flex h-full bg-wds-blue-3 w-0.5" })] })) }))] })));
};
