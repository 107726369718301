var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AccountOverviewBlock } from "./account_overview_block";
import { AccountDetailsBlock } from "./account_details_block";
import { AccountCallsBlock } from "./account_calls_block";
import { AccountContactsBlock } from "./account_contacts_block";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { createPreCallPrepBlock, deletePreCallPrepBlock, generateAccountResearchBlockOutput, getAccountResearchBlockMemberships, getAccountResearchBlockOutputs, getAllPreCallPrepBlocks, reorderAccountResearchBlocks, updatePreCallPrepBlock, } from "../../common/endpoints";
import { AccountResearchBlockCard } from "./account_research_block_card";
export const STATIC_ACCOUNT_BLOCK_IDS = {
    ACCOUNT_OVERVIEW: "account-overview",
    ACCOUNT_CALLS: "account-calls",
    ACCOUNT_CONTACTS: "account-contacts",
    ACCOUNT_DETAILS: "account-details",
};
// This hook manages everything related to account blocks:
// both static: (Account Overview, Account Details, Calls, Contacts)
// as well as dynamic research blocks that the user adds/creates.
export const useAccountResearchBlocks = ({ account, }) => {
    const queryClient = useQueryClient();
    const { data: allResearchBlocks } = useQuery({
        queryKey: ["researchBlocks"],
        queryFn: getAllPreCallPrepBlocks,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
    const { data: accountResearchBlockMemberships } = useQuery({
        queryKey: ["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id],
        queryFn: () => {
            if (!account)
                return undefined;
            return getAccountResearchBlockMemberships(account.id);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!account,
    });
    const { data: accountResearchBlockOutputs } = useQuery({
        queryKey: ["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id],
        queryFn: () => {
            if (!account)
                return undefined;
            return getAccountResearchBlockOutputs(account.id);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: !!account,
    });
    const [generationInProgressBlockIds, setGenerationInProgressBlockIds] = useState(new Set());
    const [triggeredAllBlockOutputGeneration, setTriggeredAllBlockOutputGeneration,] = useState(false);
    useEffect(() => {
        // This hook is responsible for generating research block outputs
        // once for all the available research blocks when the user
        // lands on this page.
        // We do not have a concept of default templates for account
        // research blocks yet, and hence we generate all the outputs as
        // these are light-weight as compared to calls.
        // However, this should be re-visited once we add template support
        // for account research blocks as well.
        if (triggeredAllBlockOutputGeneration) {
            // We have already triggered this hook
            return;
        }
        if (!account || !allResearchBlocks || !accountResearchBlockMemberships) {
            // Required data is not fetched from the BE yet
            return;
        }
        setTriggeredAllBlockOutputGeneration(true);
        const accountResearchBlocks = allResearchBlocks.filter((block) => block.research_block_type === "ACCOUNT");
        accountResearchBlocks.forEach((block) => {
            generateResearchBlockOutput(account.id, block.id, 
            /* forceRefresh= */ false);
        });
    }, [account, allResearchBlocks, accountResearchBlockMemberships]);
    // This ref is used to manipulate the scrolling of the blocks when
    // the user "jumps to" a different block using the dropdown.
    // Why ref instead of state?
    // This is the preferred way of such DOM manipulation in react
    // Details: https://react.dev/learn/manipulating-the-dom-with-refs
    const blocksRef = useRef(new Map());
    const getBlockRefMap = () => {
        return blocksRef.current;
    };
    // This is invoked when React mounts/unmounts each block.
    // Essentially we're setting the corresponding div ref in the map for
    // each account block when it is rendered, and unsetting it when it is
    // cleared from the DOM.
    const blocksRefCallback = (blockId, blockDivContainer) => {
        const blocksRefMap = getBlockRefMap();
        if (blockDivContainer) {
            blocksRefMap.set(blockId, blockDivContainer);
        }
        else {
            blocksRefMap.delete(blockId);
        }
    };
    const scrollToBlock = (blockId) => {
        const blocksRefMap = getBlockRefMap();
        if (blocksRefMap.has(blockId)) {
            blocksRefMap.get(blockId).scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };
    const generateResearchBlockMutation = useMutation({
        mutationFn: ({ accountId, researchBlockId, forceRefresh, }) => generateAccountResearchBlockOutput(accountId, researchBlockId, forceRefresh),
        onSuccess: (accountResearchBlockOutput) => {
            if (!accountResearchBlockOutput)
                return;
            queryClient.setQueryData(["researchBlockOutputs", account === null || account === void 0 ? void 0 : account.id], (prev) => {
                return prev
                    ? [
                        ...prev.filter((output) => output.id !== accountResearchBlockOutput.id),
                        accountResearchBlockOutput,
                    ]
                    : [accountResearchBlockOutput];
            });
            queryClient.invalidateQueries(["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id]);
            setGenerationInProgressBlockIds((prev) => {
                const updatedSet = new Set(prev);
                updatedSet.delete(accountResearchBlockOutput.research_block_id);
                return updatedSet;
            });
        },
    });
    const addNewResearchBlockMutation = useMutation({
        mutationFn: ({ dataSources, name, prompt, }) => createPreCallPrepBlock(name, dataSources, prompt, 
        /* deliveryTargets= */ [], 
        /* researchBlockType= */ "ACCOUNT"),
        onSuccess: (newBlock) => {
            if (!newBlock)
                return;
            queryClient.setQueryData(["researchBlocks"], (prevBlocks) => {
                return prevBlocks ? [...prevBlocks, newBlock] : [newBlock];
            });
        },
    });
    const updateResearchBlockMutation = useMutation({
        mutationFn: ({ blockId, dataSources, name, prompt, }) => updatePreCallPrepBlock(blockId, name, dataSources, prompt, 
        /* deliveryTargets= */ []),
        onSuccess: (updatedBlock) => {
            if (!updatedBlock)
                return;
            queryClient.setQueryData(["researchBlocks"], (prevBlocks) => {
                return prevBlocks
                    ? [
                        ...prevBlocks.filter((block) => block.id !== updatedBlock.id),
                        updatedBlock,
                    ]
                    : [updatedBlock];
            });
        },
    });
    const generateResearchBlockOutput = useCallback((accountId, researchBlockId, forceRefresh) => __awaiter(void 0, void 0, void 0, function* () {
        setGenerationInProgressBlockIds((prev) => {
            const updatedSet = new Set(prev);
            updatedSet.add(researchBlockId);
            return updatedSet;
        });
        if (!(accountResearchBlockMemberships === null || accountResearchBlockMemberships === void 0 ? void 0 : accountResearchBlockMemberships.find((membership) => membership.research_block_id === researchBlockId))) {
            // Adding a new membership directly renders the card in the UI instantly
            // with a loading block (waiting for the output).
            queryClient.setQueryData(["researchBlockMemberships", accountId], (prev) => {
                const newMembership = {
                    id: researchBlockId,
                    account_id: accountId,
                    research_block_id: researchBlockId,
                    order: Infinity,
                };
                return prev ? [...prev, newMembership] : [newMembership];
            });
        }
        yield generateResearchBlockMutation.mutateAsync({
            accountId,
            researchBlockId,
            forceRefresh,
        });
    }), [accountResearchBlockMemberships]);
    const createNewResearchBlock = (dataSources, name, prompt) => __awaiter(void 0, void 0, void 0, function* () {
        yield addNewResearchBlockMutation.mutateAsync({
            dataSources,
            name,
            prompt,
        });
    });
    const updateResearchBlock = (blockId, dataSources, name, prompt) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateResearchBlockMutation.mutateAsync({
            blockId,
            dataSources,
            name,
            prompt,
        });
        // When a block is updated, automatically regenerate the output
        if (account) {
            generateResearchBlockOutput(account.id, blockId, 
            /* forceRefresh= */ true);
        }
    });
    const deleteResearchBlockMutation = useMutation({
        mutationFn: deletePreCallPrepBlock,
        onSuccess: () => {
            queryClient.invalidateQueries(["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id]);
        },
    });
    const deleteResearchBlock = (researchBlockId) => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteResearchBlockMutation.mutateAsync(researchBlockId);
    });
    // The order of the blocks here determines the order in which they
    // will be rendered in the UI.
    const accountBlocks = useMemo(() => {
        if (!account)
            return [];
        const staticBlocks = [
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW,
                label: "Overview",
                renderer: (_props) => (_jsx(AccountOverviewBlock, { account: account, scrollToBlock: scrollToBlock }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_OVERVIEW)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_DETAILS,
                label: "Account details",
                renderer: (_props) => (_jsx(AccountDetailsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_DETAILS)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS,
                label: "Calls",
                renderer: (_props) => (_jsx(AccountCallsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CALLS)),
            },
            {
                id: STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS,
                label: "Contacts",
                renderer: (_props) => (_jsx(AccountContactsBlock, { account: account }, STATIC_ACCOUNT_BLOCK_IDS.ACCOUNT_CONTACTS)),
            },
        ];
        if (!accountResearchBlockMemberships || !allResearchBlocks) {
            return [...staticBlocks];
        }
        const researchBlocks = [
            ...accountResearchBlockMemberships
                .filter((membership) => allResearchBlocks.find((block) => block.id === membership.research_block_id))
                .sort((membership1, membership2) => membership1.order - membership2.order)
                .map((membership) => {
                const researchBlock = allResearchBlocks.find((block) => block.id === membership.research_block_id);
                const researchBlockOutput = accountResearchBlockOutputs === null || accountResearchBlockOutputs === void 0 ? void 0 : accountResearchBlockOutputs.find((output) => output.research_block_id === membership.research_block_id);
                return {
                    id: `${membership.id}`,
                    label: researchBlock.name,
                    renderer: (props) => (_jsx(AccountResearchBlockCard, Object.assign({ account: account, researchBlock: researchBlock, accountResearchBlockOutput: researchBlockOutput, outputGenerationInProgress: generationInProgressBlockIds.has(membership.research_block_id), generateResearchBlockOutput: generateResearchBlockOutput, createNewResearchBlock: createNewResearchBlock, updateResearchBlock: updateResearchBlock, deleteResearchBlock: deleteResearchBlock }, props), `${membership.id}`)),
                };
            }),
        ];
        return [...staticBlocks, ...researchBlocks];
    }, [
        account,
        allResearchBlocks,
        accountResearchBlockMemberships,
        accountResearchBlockOutputs,
        generationInProgressBlockIds,
    ]);
    const reorderResearchBlockMutation = useMutation({
        mutationFn: reorderAccountResearchBlocks,
        onSuccess: () => {
            queryClient.invalidateQueries(["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id]);
        },
    });
    const reorderResearchBlocks = (order) => __awaiter(void 0, void 0, void 0, function* () {
        queryClient.setQueryData(["researchBlockMemberships", account === null || account === void 0 ? void 0 : account.id], (oldData) => {
            if (!oldData)
                return [];
            return order.map((membershipId, index) => {
                return Object.assign(Object.assign({}, oldData.find((membership) => membership.id === membershipId)), { order: index });
            });
        });
        yield reorderResearchBlockMutation.mutateAsync(order);
    });
    return {
        staticBlocksCount: 4,
        allResearchBlocks,
        accountResearchBlockOutputs,
        accountBlocks,
        scrollToBlock,
        blocksRefCallback,
        createNewResearchBlock,
        updateResearchBlock,
        generateResearchBlockOutput,
        reorderResearchBlocks,
    };
};
