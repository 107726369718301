export const integrationListingContent = {
    Gmail: {
        shortDescription: "Receive sales call briefs over Gmail",
        longDescription: "Connect to your Gmail account to have Wiser send your team sales call briefs via email ahead of their calls with prospects.",
        capabilities: ["Receives sales call briefs in your Gmail account inbox"],
        connectButtonLabel: "Sign in with Google",
        connectButtonLogo: "https://storage.googleapis.com/wiser-ai-public2/google-logo.png",
    },
    Slack: {
        shortDescription: "Receive sales call briefs over Slack",
        longDescription: "Connect to your Slack account to have Wiser send your team sales call briefs via Slack ahead of their calls with prospects.",
        capabilities: ["Receives sales call briefs to a Slack channel or as a DM"],
    },
    Salesforce: {
        shortDescription: "Sync records with Salesforce",
        longDescription: "Connect your Salesforce account to sync your Salesforce accounts, contacts, leads, and opportunities to Wiser. Enable your sales team to close more deals by summarizing CRM data with your reps ahead of calls and pushing updates to Salesforce automatically after calls.",
        capabilities: [
            "Sync in records from Salesforce for sales call briefings and account research",
            "Sync out updates to your Salesforce records for deal and account updates based on your conversations",
        ],
    },
    "Google Calendar": {
        shortDescription: "Sync events with Google Calendar",
        longDescription: "Connect your Google account and sync events with your Google Calendar. Increase your productivity by ensuring your schedule is always up to date - without manual data entry.",
        capabilities: ["Sync events from your Google Calendar"],
        connectButtonLabel: "Sign in with Google",
        connectButtonLogo: "https://storage.googleapis.com/wiser-ai-public2/google-logo.png",
    },
    Zoom: {
        shortDescription: "Identify meeting participants & open Wiser from Zoom",
        longDescription: "Connect your Zoom account to automatically detect meeting participants and send Wiser links directly within Zoom messages.",
        capabilities: [
            "Identify participant roles based on their Wiser profiles",
            "Share Wiser links with participants seamlessly during meetings",
        ],
    },
    HubSpot: {
        shortDescription: "Sync records with HubSpot",
        longDescription: "Connect your Hubspot account to sync your Hubspot accounts, contacts, and deals with Wiser. Enable your sales team to close more deals by summarizing Hubspot data with your reps ahead of calls and pushing deal updates to Hubspot automatically after calls.",
        capabilities: [
            "Sync in records from Hubspot for sales call briefings and account research",
            "Sync out updates to your Hubspot records for deal and account updates based on your conversations",
        ],
    },
};
export const DEFAULT_TABLE_LAYOUT = {
    table_name: "calls",
    name: "Default View",
    is_default: true,
    is_public: false,
    column_order: [],
    columns: {
        select: 35,
        name: 150,
        call_time: 150,
        call_type: 60,
        transcripts: 150,
        account_name: 80,
        wiser_joined: 40,
        playbook_items: 150,
        opportunity_name: 120,
    },
    query_string: 'page=1&per_page=10&order_by=[{"field":"call_time","order":"DESC"}]&filter={"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"AND","subconditions":[{"where_condition":{"operator":"EQUALS","field":"call_type","value":"External"}},{"where_condition":{"operator":"EQUALS","field":"wiser_joined","value":true}},{"where_condition":{"operator":"IN","field":"call_time","value":"Last+2+weeks"}}]}}]}}',
};
