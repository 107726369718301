import { FilterOperator } from "./types";
export const DEFAULT_OPERATORS_BY_TYPE = {
    string: new Set([
        FilterOperator.EQUALS,
        FilterOperator.NOT_EQUAL,
        FilterOperator.CONTAINS,
        FilterOperator.DOES_NOT_CONTAIN,
        FilterOperator.IS_EMPTY,
        FilterOperator.IS_NOT_EMPTY,
        FilterOperator.STARTS_WITH,
        FilterOperator.ENDS_WITH,
    ]),
    number: new Set([
        FilterOperator.EQUALS,
        FilterOperator.NOT_EQUAL,
        FilterOperator.GREATER_THAN_OR_EQUAL,
        FilterOperator.LESS_THAN_OR_EQUAL,
    ]),
    date: new Set([
        FilterOperator.EQUALS,
        FilterOperator.NOT_EQUAL,
        FilterOperator.GREATER_THAN_OR_EQUAL,
        FilterOperator.LESS_THAN_OR_EQUAL,
    ]),
    boolean: new Set([FilterOperator.EQUALS, FilterOperator.NOT_EQUAL]),
    reference: new Set([FilterOperator.IN]),
    select: new Set([FilterOperator.EQUALS, FilterOperator.NOT_EQUAL]),
    relative_date: new Set([FilterOperator.IN]),
};
export const NULL_VALUE_ALLOWED_OPERATORS = [
    FilterOperator.IS_EMPTY,
    FilterOperator.IS_NOT_EMPTY,
];
